<template>
    <CRow>
        <CToaster position='top-right' :autohide='1500'>
            <template v-for='(toast, index) in toasts'>
                <CToast
                    :key="'toast' + toast.text + index"
                    :show='true'
                    :header="toast.header === '' ? null : toast.header"
                >
                    {{ toast.text }}
                </CToast>
            </template>
        </CToaster>
        <CCol class='col-xl-4 col-lg-12 col-12'>
            <CCard>
                <CCardHeader>
                    <strong>Yeni Kategori Oluştur</strong>
                </CCardHeader>
                <CCardBody>
                    <CRow>
                        <CCol sm='12'>
                            <CInput
                                label='Kategori Başlığı'
                                placeholder='Kategori Başlığı'
                                v-model='title'
                            />
                        </CCol>
                    </CRow>
                    <CRow>
                        <CCol sm='12'>
                            <CInput
                                label='Kategori Açıklaması'
                                placeholder='Kategori Açıklaması'
                                v-model='description'
                            />
                        </CCol>
                    </CRow>
                    <CRow>
                        <CCol sm='12'>
                            <CInput
                                disabled
                                label='Slug'
                                placeholder='slug'
                                v-model='this.slug'
                                description='ex: kategori-aciklamasi'
                            />
                        </CCol>
                    </CRow>
                    <CRow class='mt-4 sm-12 ml-1 justify-content-center'>
                        <croppa
                            :placeholder="'Fotoğraf Yüklemek İçin Tıklayınız'"
                            :placeholder-font-size='16'
                            v-model='croppa'
                            :width='256'
                            :height='256'
                            :quality='2'
                            initial-size='contain'
                        >
                        </croppa>
                    </CRow>
                    <CRow class='justify-content-center'>
                        <small>
                            Minimum 512x512 fotoğraf yükleyiniz. Maksimum dosya Boyutu 2MB olmalıdır.
                        </small>
                    </CRow>
                    <CRow class='justify-content-center'>
                        <CCol class='mt-5 d-flex justify-content-center align-items-center'>
                            <VueLoadingButton
                                style='background-color: #26994D; font-size: 14px; background-size: auto'
                                aria-label='Post message'
                                class='button'
                                @click.native='addCategory'
                                :loading='isLoading'
                                :styled='isStyled'
                            >Kategoriyi Ekle
                            </VueLoadingButton>
                        </CCol>
                    </CRow>
                </CCardBody>
            </CCard>
        </CCol>
        <CCol class='col-xl-8 col-lg-12 col-12'>
            <CCard>
                <CCardHeader>
                    <strong>Kategori Listesi</strong>
                </CCardHeader>
                <CCardBody>
                    <CInput label='Arama: ' placeholder='Aramak istediğiniz kategoriyi yazınız...' :horizontal="{ label: 'w-auto', input: 'w-100'}" v-model='filterValue' />
                    <CDataTable
                        class='mb-0 table-outline'
                        hover
                        :items='tableItems'
                        :fields='tableFields'
                        head-color='light'
                        :items-per-page='itemsPerPage'
                        :loading='isDataLoading'
                    >
                        <template #no-items-view>
                            <CRow>
                                <CCol class='col-12 font-3xl text-center'>
                                    <CIcon name='cil-x' />
                                    Veri Bulunamadı
                                </CCol>
                            </CRow>
                        </template>
                        <td slot='id' slot-scope='{item}'>
                            <div>{{ item.id }}</div>
                            <div class='small text-muted'>
                            </div>
                        </td>
                        <td slot='title' slot-scope='{item}'>
                            <div class='clearfix'>
                            </div>
                            <div>{{ item.title }}</div>
                        </td>
                        <td
                            slot='totalWordTutorialCount'
                            slot-scope='{item}'
                            class='text-center'
                        >
                            <div>{{ item.totalWordTutorialCount }}</div>
                        </td>
                        <td
                            slot='description'
                            slot-scope='{item}'
                            class='text-center'
                        >
                            <div>{{ item.description }}</div>
                        </td>

                        <td slot='manage' slot-scope='{item}'>
                            <CRow>
                                <router-link :to="'/kategorileri-yonet/' + item.id" tag='button' role='button' block
                                             class='btn btn-success mb-1 ml-1'>
                                    <CIcon name='cil-pencil' />
                                </router-link>
                                <CButton @click='showAlert(item.id)' color='danger mb-1 ml-1'>
                                    <CIcon name='cil-x' />
                                </CButton>
                            </CRow>
                        </td>
                    </CDataTable>
                </CCardBody>
            </CCard>
        </CCol>
    </CRow>
</template>

<script>
import { WordTutorialCategoryAdministration } from '@/services/api-service'
import VueLoadingButton from 'vue-loading-button'
import kebabCase from 'lodash.kebabcase'

export default {
    name: 'Categories',
    components: {
        VueLoadingButton,
    },
    mounted() {
        this.loadCategories()
    },
    watch: {
        title(newValue) {
            this.slug = kebabCase(newValue)
        },
        filterValue() {
            this.loadCategories()
        },
        currentPage() {
            this.loadCategories()
        },
        itemsPerPage() {
            this.loadCategories()
        },
    },
    data() {
        return {
            isLoading: false,
            isStyled: true,
            filterValue: '',
            currentPage: 1,
            itemsPerPage: 50,
            totalPages: 1,
            croppa: '',
            title: '',
            slug: '',
            description: '',
            isDataLoading: false,
            toasts: [],
            tableItems: [],
            tableFields: [
                { key: 'id', label: 'ID' },
                { key: 'title', label: 'Başlık' },
                { key: 'totalWordTutorialCount', label: 'Toplam kelime Sayısı', _classes: 'text-center' },
                { key: 'description', label: 'Kategori Açıklaması' },
                { key: 'slug', label: 'slug', _classes: 'text-center' },
                { key: 'manage', label: 'Yönet' },
            ],
        }
    },
    methods: {
        blobConvert() {
            return this.croppa.promisedBlob()
        },
        color(value) {
            let $color
            if (value <= 25) {
                $color = 'info'
            } else if (value > 25 && value <= 50) {
                $color = 'success'
            } else if (value > 50 && value <= 75) {
                $color = 'warning'
            } else if (value > 75 && value <= 100) {
                $color = 'danger'
            }
            return $color
        },
        showAlert(id) {
            this.$swal({
                title: 'Emin misiniz?',
                text: 'Yaptığınız İşlem Geri Alınamayacaktır!',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Evet, Sil',
                cancelButtonText: 'Vazgeç',
            }).then(async (result) => {
                if (result.isConfirmed) {
                    this.tableItems = this.tableItems.filter(r => r.id !== id)
                    await WordTutorialCategoryAdministration.deleteWordTutorialCategory(id)
                    await this.$swal.fire(
                        'Silindi!',
                        'İşlem Başarıyla Gerçekleştirildi!',
                        'success',
                    )
                }
            })
        },
        showToast: function(text = 'İşlem Başarıyla Onaylandı', header = 'Bilgi') {
            this.toasts.push({ text, header })
        },
        async loadCategories() {
            this.isDataLoading = true
            const result = await WordTutorialCategoryAdministration.listWordTutorialCategoriesForAdministration(this.currentPage, this.itemsPerPage, this.filterValue)
            this.tableItems = result.data.wordTutorialCategoryList
            this.isDataLoading = false
        },
        async addCategory() {
            this.isLoading = true
            try {
                const convertedImage = await this.blobConvert()
                const result = await WordTutorialCategoryAdministration.createWordTutorialCategory(convertedImage, this.title, this.slug, this.description)
                const newCategory = {
                    id: result.data.createdWordTutorialCategoryId,
                    title: this.title,
                    slug: this.slug,
                    description: this.description,
                }
                this.isLoading = false
                this.tableItems = [newCategory, ...this.tableItems]
                this.$toast.success('Yeni Kategori Başarıyla Eklendi!')
                this.title = ''
                this.slug = ''
                this.description = ''
            } catch (e) {
            }
        },
    },
}
</script>
